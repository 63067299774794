import { typePageOptions } from '@/utils';
import { text } from '@/utils/icons';
import { required } from '@/utils/rules';
import { accountingAccountType } from '@/utils/options';
import TypeInstallmentBusiness from '@/business/TypeInstallmentBusiness';

export default {
  domain: 'accounting_accounts',
  domainSingle: 'accounting_account',
  business: TypeInstallmentBusiness,
  title: 'Conta Contábil',
  description: 'Lista de todos os Conta Contábil do sistema',
  createBtn: 'Nova Conta Contábel',
  showInactive: false,
  routes: {
    list: {
      name: 'accounting-accounts',
      path: '/conta-contabels',
      meta: {
        rule: 'isAdmin',
        typePage: typePageOptions.list,
        requiresAuth: true,
      },
    },
    create: {
      name: 'accounting-account-create',
      path: '/conta-contabels/novo',
      meta: {
        rule: 'isAdmin',
        typePage: typePageOptions.create,
        requiresAuth: true,
      },
    },
    show: {
      name: 'accounting-account-show',
      path: '/conta-contabels/:id',
      meta: {
        rule: 'isAdmin',
        typePage: typePageOptions.show,
        requiresAuth: true,
      },
    },
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: false,
      items: [],
    },
  },
  filters: {
    has: false,
    items: [],
  },
  fields: [
    {
      title: 'Dados da Conta Contábel',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'name',
          formattedName: 'name',
          label: 'Nome',
          align: '',
          md: '5',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'code',
          formattedName: 'code',
          label: 'Código',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'select',
          name: 'type',
          formattedName: 'type_formatted',
          label: 'Tipo',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          items: accountingAccountType,
          itemText: 'text',
          itemValue: 'value',
        },
        {
          type: 'text',
          name: 'credit_code',
          formattedName: 'credit_code',
          label: 'Código de Crédito',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'debit_code',
          formattedName: 'debit_code',
          label: 'Código de Débito',
          align: '',
          md: '4',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
        },
      ],
    },
  ],
  footer: [],
};
