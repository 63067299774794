/* eslint-disable no-unused-vars */
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueHtmlToPaper from 'vue-html-to-paper';
import * as Sentry from '@sentry/vue';
import Icons from './plugins/icons';
// import Options from './plugins/options';
import Enums from './plugins/enums';
import Services from './plugins/services';
import Locales from './plugins/locales';
import Schemas from './plugins/schemas';
import './plugins/vuetify-mask.js';
import './plugins/toasts';
import router from './router';
import { makeServer } from './server/index';
import { enableMirage } from './utils';
import VueApexCharts from 'vue-apexcharts';
import store from './store';
// import acl from './acl/index.js';

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

if (enableMirage()) {
  makeServer();
}

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    'public/css/danf55.css',
  ],
  timeout: 1000,
  autoClose: true,
  windowTitle: '',
};

Vue.config.productionTip = false;
Vue.use(Icons);
// Vue.use(Options);
Vue.use(Services);
Vue.use(Enums);
Vue.use(Locales);
Vue.use(Schemas);

const toPaper = VueHtmlToPaper;
Vue.use(toPaper, options);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    dsn: 'https://37f500a6783d448caf62b691a5cb9b7a@o4505432102928384.ingest.sentry.io/4505432105615360',
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

new Vue({
  vuetify,
  router,
  // acl,
  store,
  render: (h) => h(App),
}).$mount('#app');
