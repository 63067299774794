<template>
  <ExpansionPanel v-model="panel" :title="l.title" :icon="$icons.box" readonly>
    <v-form v-model="validFormDetails" ref="validFormDetails" lazy-validation>
      <Row>
        <Col :cols="classCols">
          <AutoComplete v-model="order.client" :placeholder="placeholderClient"
            :label="labelClient" :rules="[rulesFields('client')]" return-object
            domain="register" resultDomain="registers"
            :search-by="['name', 'social_name', 'cpf', 'cnpj', 'cell_phone']" item-text-data-serve="social_name"
            title="Pesquise por: Nome, E-mail, Telefone, CPF e CNPJ"
            item-value-data-serve="id" :readonly="isDelivered" condition-or
            @change="changeAutoComplete($event, 'client', 'client_id')" />
        </Col>
        <Col :cols="classCols">
          <Select v-model="order.seller" :placeholder="textSeller"
            :label="textSeller" :items="propsComponents.sellers" :rules="[rulesFields('seller')]"
            return-object @change="changeSelects('seller', 'seller_id', $event)" :readonly="isDelivered" />
        </Col>
        <Col :cols="classCols">
          <TextFieldInteger v-if="isOrderNumberSequential" v-model="order.order_number" :placeholder="l.form.orderNumber.placeholder"
            :rules="[rules.required]" :label="labelOrderNumber" :readonly="isDelivered || isOrderNumberSequential" />
          <TextField v-else v-model="order.order_number" :placeholder="l.form.orderNumber.placeholder"
            :rules="[rules.required]" :label="labelOrderNumber" :readonly="isDelivered" />
        </Col>
        <Col :cols="classCols">
          <TextField v-model="order.solicitation_number" :placeholder="l.form.solicitationNumber.placeholder"
            :label="l.form.solicitationNumber.label" :readonly="isDelivered" />
        </Col>
      </Row>
      <Row class="mt-0">
        <Col :cols="classCols">
          <Select v-model="order.presence_indicator" :placeholder="l.form.presenceIndicator.placeholder"
            :label="l.form.presenceIndicator.label" :items="propsComponents.presenceIndicator" :rules="[rules.required]"
            :readonly="isDelivered" :loading="propsComponents.loadingSelects" />
        </Col>
        <Col :cols="classCols">
          <Select v-model="order.stock" :placeholder="l.form.stock.placeholder" :label="l.form.stock.label"
            :items="propsComponents.stocks" :rules="[]" return-object
            @change="changeSelects('stock', 'stock_id')" :readonly="isDelivered"
            :loading="propsComponents.loadingSelects" />
        </Col>
        <Col :cols="classCols">
          <Select v-model="order.price_list" :placeholder="l.form.priceList.placeholder"
            :label="l.form.priceList.label" return-object :clearable="!isDelivered"
            :items="propsComponents.priceLists"
            @change="changeSelects('price_list', 'price_list_id')" :readonly="isDelivered"
            :loading="propsComponents.loadingSelects" />
        </Col>
        <Col :cols="classCols">
          <TextField v-model="order.invoice_number" :placeholder="l.form.invoiceNumber.placeholder"
            :label="l.form.invoiceNumber.label" readonly />
        </Col>
      </Row>
      <Row class="mt-0">
        <Col :cols="classCols">
          <Select v-model="order.nature_operation" :placeholder="l.form.natureOperation.placeholder"
            :label="l.form.natureOperation.label" :items="propsComponents.natureOperations" :rules="[rulesFields('nature_operation')]"
            return-object @change="changeSelects('nature_operation', 'nature_operation_id')" :readonly="isDelivered" />
        </Col>
        <Col :cols="classCols">
          <Select v-model="order.payment_method" :placeholder="l.form.paymentMethod.placeholder"
            :label="l.form.paymentMethod.label" :items="propsComponents.paymentMethods"
            return-object @change="changeSelects('payment_method', 'payment_method_id', $event)"
            :readonly="isDelivered" clearable />
        </Col>
        <Col :cols="classCols">
          <Select v-model="order.type_installment" :placeholder="l.form.typeInstallment.placeholder"
            :label="l.form.typeInstallment.label" :items="propsComponents.typeInstallments"
            return-object @change="changeSelects('type_installment', 'type_installment_id', $event)"
            :readonly="isDelivered" clearable />
        </Col>
        <Col :cols="classCols">
          <DataPicker v-model="order.emission_date" :placeholder="l.form.emissionDate.placeholder"
            :label="l.form.emissionDate.label" :rules="[rules.required]" :readonly="isDelivered"
            @clearDate="changeDate($event, 'emission_date')" />
        </Col>
        <Col :cols="classCols">
          <DataPicker v-model="order.prevision_date" :placeholder="l.form.previsionDate.placeholder"
            :label="l.form.previsionDate.label" :readonly="isDelivered"
            @clearDate="changeDate($event, 'prevision_date')" />
        </Col>
        <Col :cols="classCols">
          <DataPicker v-model="order.delivery_date" :placeholder="l.form.deliveryDate.placeholder"
            :label="l.form.deliveryDate.label" :readonly="isDelivered" @clearDate="changeDate($event, 'delivery_date')" />
        </Col>
      </Row>
      <Row class="mt-0">
        <Col cols="12" xs="12" sm="12" :md="3" :lg="3">
          <CheckBox v-model="order.is_consumer_final" @setCheckBox="setConsumerFinal" :label="l.form.finalConsumer.label"
            :readonly="isDelivered || isConsumerFinalReadonly" />
        </Col>
        <Col cols="12" xs="12" sm="12" :md="3" :lg="3">
          <CheckBox v-model="order.is_wholesale" @setCheckBox="setWholesale" :label="l.form.wholesale.label"
            :readonly="isDelivered || isWholesaleReadonly" />
        </Col>
      </Row>
    </v-form>
  </ExpansionPanel>
</template>

<script>
import ExpansionPanel from '@/components/vuetify/ExpansionPanel';
import {
  Row,
  Col,
  Select,
  TextField,
  CheckBox,
  DataPicker,
  AutoComplete,
  TextFieldInteger,
} from '@/components/vuetify';
import { required } from '@/utils/rules';
import UpdateInfosOrderSaleMixin from '../mixins/UpdateInfosOrderSaleMixin';
import { snakeCase, kebabCase } from 'lodash';
import OrderNumberMixin from '../mixins/OrderNumberMixin';

export default {
  name: 'OrderDetails',
  components: {
    ExpansionPanel,
    Select,
    Row,
    Col,
    TextField,
    CheckBox,
    DataPicker,
    AutoComplete,
    TextFieldInteger
  },
  props: {
    companySetting: {
      type: Object,
      required: true
    }
  },
  inject: ['propsComponents'],
  mixins: [UpdateInfosOrderSaleMixin, OrderNumberMixin],
  data () {
    return {
      panel: [0],
      validFormDetails: false,
      rules: {
        required: required
      },
      isConsumerFinalReadonly: false,
      labelOrderNumber: '',
    };
  },
  computed: {
    l () {
      return this.$locales.pt.orders.create.details;
    },
    isAllPurchase () {
      return kebabCase(this.$route.meta.type) === this.$enums.orderTypes.ORDER_PURCHASE ||
        kebabCase(this.$route.meta.type) === this.$enums.orderTypes.ORDER_PURCHASE_DEVOLUTION;
    },
    labelClient () {
      let label = this.l.form.client.label;

      if (this.isAllPurchase) {
        label = this.l.form.provider.label;
      }

      return label;
    },
    placeholderClient() {
      let label = this.model.client_id ? this.l.form.provider.placeholder : 'Não Informado';

      if (this.isAllPurchase) {
        label = this.model.client_id ? this.l.form.provider.placeholder : 'Não Informado';
      }

      return label;
    },
    textSeller() {
      let label = this.model.seller_id ? this.l.form.seller.label : 'Não Informado';

      if (this.isAllPurchase) {
        label = this.model.seller_id ? this.l.form.employee.label : 'Não Informado';
      }

      return label;
    },
    isOrderNumberSequential () {
      return this.companySetting.is_order_number_sequential;
    },
    isWholesaleReadonly() {
      return this.order.items?.length > 0;
    },
  },
  watch: {
    order: {
      handler (order) {
        this.isConsumerFinalReadonly = order.client?.taxpayer_type === 'non_taxpayer' || false;
      },
      deep: true,
    },
    companySetting: {
      handler () {
        this.labelOrderNumber = this.l.form.orderNumber.label;

        if (this.companySetting.is_order_number_sequential) {
          this.order.order_number = this.setOrderNumber();
        } else {
          this.labelOrderNumber = `${this.labelOrderNumber} (Ultimo: ${this.companySetting[`last_${snakeCase(this.$route.meta.type)}_number`]})`;
        }
      },
      deep: true
    }
  },
  methods: {
    setConsumerFinal (value) {
      if (this.order.client?.item?.taxpayer_type === this.$enums.taxPayer.NON_TAXPAYER) {
        this.order.is_consumer_final = true;

        return;
      }
      this.order.is_consumer_final = value || false;
    },
    setWholesale (value) {
      this.order.is_wholesale = value || false;
    },
    rulesFields (field) {
      const fieldsRequired = ['client', 'seller', 'order_number', 'presence_indicator', 'nature_operation'];

      if (fieldsRequired.includes(field) && !this.model.is_pdv) {
        return this.rules.required;
      }

      return true;
    },
    changeDate (value, item) {
      if (!value) {
        this.order[item] = null;
      }
    },
  },
};
</script>
