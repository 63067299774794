import { typePageOptions, getCurrentPeriod } from '@/utils';
import { text, fileDocumentCheckOutline, printer, view, calendar, plus, keyboardReturn, cancel, list } from '@/utils/icons';
import OrderBusiness from '@/business/OrderBusiness';
import GenerateDevolutionOrder from '../components/listOrders/GenerateDevolutionOrder';
import { required } from '@/utils/rules';
import { typePageOrders } from '@/utils/enums';

export default {
  domain: 'orders',
  domainSingle: 'order',
  business: OrderBusiness,
  title: 'Movimentação',
  description: 'Nova Movimentação',
  createBtn: '',
  showInactive: false,
  showExpand: true,
  routes: {
    orderSale: {
      list: {
        name: 'order-sales',
        path: '/pedido-vendas',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: typePageOrders.ORDER_SALE, title: 'Vendas', description: 'Lista de todas as Vendas cadastradas no sistema', }
      },
      create: {
        name: 'order-sale-create',
        path: '/pedido-vendas/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true,
          type: typePageOrders.ORDER_SALE, title: 'Vendas', description: 'Lista de todas as Vendas cadastradas no sistema', }
      },
      show: {
        name: 'order-sale-show',
        path: '/pedido-vendas/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: typePageOrders.ORDER_SALE, title: 'Vendas', description: 'Lista de todas as Vendas cadastradas no sistema', }
      }
    },
    orderPurchase: {
      list: {
        name: 'order-purchases',
        path: '/pedido-compras',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: typePageOrders.ORDER_PURCHASE, title: 'Compras', description: 'Lista de todas as Compras cadastradas no sistema', }
      },
      create: {
        name: 'order-purchase-create',
        path: '/pedido-compras/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true,
          type: typePageOrders.ORDER_PURCHASE, title: 'Compras', description: 'Lista de todas as Compras cadastradas no sistema', }
      },
      show: {
        name: 'order-purchase-show',
        path: '/pedido-compras/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: typePageOrders.ORDER_PURCHASE, title: 'Compras', description: 'Lista de todas as Compras cadastradas no sistema', }
      }
    },
    orderBudget: {
      list: {
        name: 'order-budgets',
        path: '/pedido-orcamentos',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: typePageOrders.BUDGET, title: 'Orçamentos', description: 'Lista de todos os Orçamentos cadastrados no sistema', }
      },
      create: {
        name: 'order-budget-create',
        path: '/pedido-orcamentos/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true,
          type: typePageOrders.BUDGET, title: 'Orçamentos', description: 'Lista de todos os Orçamentos cadastrados no sistema', }
      },
      show: {
        name: 'order-budget-show',
        path: '/pedido-orcamentos/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: typePageOrders.BUDGET, title: 'Orçamentos', description: 'Lista de todos os Orçamentos cadastrados no sistema', }
      }
    },
    orderSaleDevolution: {
      list: {
        name: 'order-sale-devolutions',
        path: '/pedido-venda-devolucoes',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: typePageOrders.ORDER_SALE_DEVOLUTION, title: 'Devoluções de Vendas', description: 'Lista de todas as Devoluções de Vendas cadastradas no sistema', }
      },
      create: {
        name: 'order-sale-devolution-create',
        path: '/pedido-venda-devolucoes/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true,
          type: typePageOrders.ORDER_SALE_DEVOLUTION, title: 'Devoluções de Vendas', description: 'Lista de todas as Devoluções de Vendas cadastradas no sistema', }
      },
      show: {
        name: 'order-sale-devolution-show',
        path: '/pedido-venda-devolucoes/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: typePageOrders.ORDER_SALE_DEVOLUTION, title: 'Devoluções de Vendas', description: 'Lista de todas as Devoluções de Vendas cadastradas no sistema', }
      }
    },
    orderPurchaseDevolution: {
      list: {
        name: 'order-purchase-devolutions',
        path: '/pedido-compra-devolucoes',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: typePageOrders.ORDER_PURCHASE_DEVOLUTION, title: 'Devoluções de Compras', description: 'Lista de todas as Devoluções de Compras cadastradas no sistema', }
      },
      create: {
        name: 'order-purchase-devolution-create',
        path: '/pedido-compra-devolucoes/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true,
          type: typePageOrders.ORDER_PURCHASE_DEVOLUTION, title: 'Devoluções de Compras', description: 'Lista de todas as Devoluções de Compras cadastradas no sistema', }
      },
      show: {
        name: 'order-purchase-devolution-show',
        path: '/pedido-compra-devolucoes/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: typePageOrders.ORDER_PURCHASE_DEVOLUTION, title: 'Devoluções de Compras', description: 'Lista de todas as Devoluções de Compras cadastradas no sistema', }
      }
    },
    orderSalePdv: {
      list: {
        name: 'order-sales-pdv',
        path: '/pedido-vendas-pdv',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: typePageOrders.ORDER_SALE_PDV, title: 'PDV Vendas', description: 'Lista de todas as Vendas Realizadas pelo PDV', }
      },
      show: {
        name: 'order-sale-pdv-show',
        path: '/pedido-vendas-pdv/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: typePageOrders.ORDER_SALE_PDV, title: 'PDV Vendas', description: 'Lista de todas as Vendas Realizadas pelo PDV', }
      }
    },
    orderConsigned: {
      list: {
        name: 'order-sales-consigned',
        path: '/pedido-consignados',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: typePageOrders.ORDER_CONSIGNED, title: 'Consigandos', description: 'Lista de todas as Consigandas cadastradas no sistema', }
      },
      create: {
        name: 'order-sales-consigned-create',
        path: '/pedido-consignados/novo',
        meta: { typePage: typePageOptions.create, requiresAuth: true,
          type: typePageOrders.ORDER_CONSIGNED, title: 'Consigandos', description: 'Lista de todas as Consigandas cadastradas no sistema', }
      },
      show: {
        name: 'order-sales-consigned-show',
        path: '/pedido-consignados/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: typePageOrders.ORDER_CONSIGNED, title: 'Consigandos', description: 'Lista de todas as Consigandoas cadastradas no sistema', }
      }
    },
    orderSaleTable: {
      list: {
        name: 'order-sales-table',
        path: '/pedido-vendas-mesas',
        meta: { typePage: typePageOptions.list, requiresAuth: true,
          type: typePageOrders.ORDER_TABLE, title: 'Mesas Vendas', description: 'Lista de todas as Vendas Realizadas pelas Mesas', }
      },
      show: {
        name: 'order-sale-table-show',
        path: '/pedido-vendas-mesas/:id',
        meta: { typePage: typePageOptions.show, requiresAuth: true,
          type: typePageOrders.ORDER_TABLE, title: 'Mesas Vendas', description: 'Lista de todas as Vendas Realizadas pela Mesas', }
      }
    },
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: true,
      items: [
        {
          icon: fileDocumentCheckOutline,
          name: 'generateInvoice',
          label: 'Gerar NF-e',
          action: 'generateInvoice'
        },
        {
          icon: view,
          name: 'showInvoice',
          label: 'Ver NF-e',
          action: 'showInvoice'
        },
        {
          icon: plus,
          name: 'duplicateOrder',
          label: 'Duplicar Venda',
          action: 'duplicateOrder'
        },
        {
          icon: keyboardReturn,
          name: 'devolutionOrder',
          iconColor: 'primary',
          label: 'Devolver Pedido',
          action: 'devolutionOrder',
          component: GenerateDevolutionOrder,
          version: 'v2',
          updateListWhenClosing: true,
          maxWidth: 800,
        },
        {
          icon: cancel,
          name: 'reverseOrder',
          iconColor: 'primary',
          label: 'Estornar Pedido',
          action: 'reverseOrder',
          updateListWhenClosing: true,
          maxWidth: 800,
          isConfirmDialog: true,
          titleDialog: 'Cancelar Balanço',
          messageDialog: 'Deseja realmente cancelar o balanço?',
        },
        {
          icon: printer,
          name: 'print',
          label: 'Imprimir',
          action: 'print'
        },
        {
          icon: printer,
          name: 'printCoupon',
          label: 'Cupom não Fiscal',
          action: 'printCoupon'
        },
        // {
        //   icon: download,
        //   name: 'download',
        //   label: 'Download',
        //   action: 'download'
        // }
      ]
    },
  },
  filters: {
    has: true,
    isBetween: true,
    columnBetween: 'created_at',
    items: [
      {
        type: 'dataPicker',
        name: 'initial_date',
        isBetween: true,
        isPermanent: true,
        label: 'Data Inicial',
        defaultValue: getCurrentPeriod().currentPeriod.start,
        defaultValueFormatted: getCurrentPeriod().currentPeriod.startFormatted,
        icon: calendar,
        md: 6,
      },
      {
        type: 'dataPicker',
        name: 'final_date',
        isBetween: true,
        isPermanent: true,
        label: 'Data Final',
        defaultValue: getCurrentPeriod().currentPeriod.end,
        defaultValueFormatted: getCurrentPeriod().currentPeriod.endFormatted,
        icon: calendar,
        md: 6,
      },
      {
        type: 'select',
        name: 'is_wholesale',
        label: 'Tipo Venda',
        icon: list,
        itemText: 'text',
        itemValue: 'value',
        items: {
          data: [{ text: 'Varejo', value: 'no' }, { text: 'Atacado', value: 'yes' }]
        },
        md: 4,
      },
      {
        type: 'text',
        name: 'order_number',
        label: 'N° Pedido',
        icon: text,
        md: 4,
      },
      {
        type: 'text',
        name: 'invoice_number',
        label: 'N° NF-e',
        icon: text,
        md: 4,
      },
      {
        type: 'select',
        name: 'is_pdv',
        label: 'Venda',
        icon: list,
        itemText: 'text',
        itemValue: 'value',
        items: {
          data: [{ text: 'Normal', value: 'no' }, { text: 'PDV', value: 'yes' }]
        },
        md: 4,
      },
      {
        type: 'text',
        name: 'client$$name',
        label: 'Nome do Cliente',
        icon: text,
        md: 4,
      },
      {
        type: 'text',
        name: 'seller$$name',
        label: 'Nome do Vendedor',
        icon: text,
        md: 4,
      },
      {
        type: 'simpleMask',
        name: 'client$$cnpj',
        label: 'CNPJ do Cliente',
        icon: text,
        md: 6,
        inputMask: '##.###.###/####-##',
        outputMask: '##############',
        clearable: true,
      },
      {
        type: 'simpleMask',
        name: 'client$$cpf',
        label: 'CPF do Cliente',
        icon: text,
        md: 6,
        inputMask: '###.###.###-##',
        outputMask: '###########',
        clearable: true,
      },
    ]
  },
  fields: [
    {
      title: 'Dados do Pedido',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'created_at',
          formattedName: 'created_at_formatted',
          label: 'Data',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          width: 90
        },
        {
          type: 'text',
          name: 'order_number',
          formattedName: 'order_number',
          label: 'N°',
          align: '',
          md: '2',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          width: 80
        },
        {
          type: 'text',
          name: 'invoice_number',
          formattedName: 'invoice_number',
          label: 'N° NFe',
          align: '',
          md: '3',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'client',
          formattedName: 'client.name',
          label: 'Cliente',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'text',
          name: 'client',
          formattedName: 'client.address.city_formatted',
          label: 'Cidade/UF',
          align: '',
          md: '3',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'seller.name',
          formattedName: 'seller.name',
          label: 'Vendedor',
          align: '',
          md: '3',
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'total_products',
          formattedName: 'total_products_formatted',
          label: 'Total Produtos',
          align: '',
          md: '3',
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'total_discount_value',
          formattedName: 'total_discount_value_formatted',
          label: 'Desconto',
          align: '',
          md: '3',
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'total_taxes',
          formattedName: 'total_taxes_formatted',
          label: 'Impostos',
          align: '',
          md: '3',
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'total_freight',
          formattedName: 'total_freight_formatted',
          label: 'Frete',
          align: '',
          md: '3',
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'total_expense',
          formattedName: 'total_expense_formatted',
          label: 'Outras Despesas',
          align: '',
          md: '3',
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'total_value_replacement',
          formattedName: 'total_value_replacement_formatted',
          label: 'Troca',
          align: '',
          md: '3',
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'total_order',
          formattedName: 'total_order_formatted',
          label: 'Total Final',
          align: '',
          md: '3',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          width: 110
        },
        {
          type: 'text',
          name: 'is_pdv',
          formattedName: 'is_pdv_formatted',
          label: 'Venda',
          align: '',
          md: '3',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'is_wholesale',
          formattedName: 'is_wholesale_formatted',
          label: 'Tipo',
          align: '',
          md: '3',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'delivery_date',
          formattedName: 'delivery_date_formatted',
          label: 'Data Entrega',
          align: '',
          md: '3',
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'is_delivered',
          label: 'Situação',
          list: true,
          width: 130
        },
      ],
    },
    {
      title: 'Dados do Cliente',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'client.name',
          formattedName: 'client.name',
          label: 'Nome',
          align: '',
          md: '4',
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'client.email',
          formattedName: 'client.email',
          label: 'E-mail',
          align: '',
          md: '4',
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'client.phone_formatted',
          formattedName: 'client.phone_formatted',
          label: 'Telefone',
          align: '',
          md: '3',
          contentExpanded: true,
        },
        {
          type: 'text',
          name: 'client.cell_phone_formatted',
          formattedName: 'client.cell_phone_formatted',
          label: 'Celular',
          align: '',
          md: '3',
          contentExpanded: true,
        },
      ]
    },
  ],
  footer: [
    {
      name: 'value_total_pending',
      label: 'Vlr. Total Pendentes',
      color: 'primary',
      md: 3,
    },
    {
      name: 'value_total_delivered',
      label: 'Vlr. Total Entregues',
      color: 'primary',
      md: 3,
    },
  ],
  create: {
    items: {
      headers: [
        {
          text: 'Item',
          value: 'item_number',
        },
        {
          text: 'Cod. Produto',
          value: 'code',
        },
        {
          text: 'Nome',
          value: 'name',
        },
        {
          text: 'Qtde.',
          value: 'quantity',
        },
        {
          text: 'Vlr. Uni.',
          value: 'unit_value',
        },
        {
          text: 'Sub Total',
          value: 'sub_total',
        },
        {
          text: 'Vlr. Desc',
          value: 'discount_value',
        },
        {
          text: 'Vlr. Total',
          value: 'total_value',
        },
        {
          text: 'Ações',
          value: 'actions',
          width: 30,
          sortable: false,
          align: 'end',
          cellClass: 'action-column-items',
        },
      ],
      dialogProductVariations: {
        headers: [
          {
            text: 'Produto',
            value: 'name'
          },
          {
            text: 'Cód. Barras',
            value: 'bar_code'
          },
          {
            text: 'Preço Varejo',
            value: 'price_formatted'
          },
          {
            text: 'Preço Atacado',
            value: 'price_wholesale_formatted'
          },
          {
            text: 'Ação',
            value: 'actions',
            align: 'end'
          }
        ]
      }
    },
    taxes: {
      headers: [
        {
          text: 'Item',
          value: 'item_number',
          width: 80,
          sortable: false,
        },
        {
          text: 'Nome',
          value: 'name',
          width: 200,
          sortable: false,
        },
        {
          text: 'CFOP',
          value: 'cfop',
          width: 40,
          sortable: false,
        },
        {
          text: 'Simples Nacional',
          value: 'national_simple',
          sortable: false,
        },
        {
          text: 'ICMS',
          value: 'icms',
          sortable: false,
        },
        {
          text: 'ICMS ST',
          value: 'icms_st',
          sortable: false,
        },
      ]
    }
  },
};
