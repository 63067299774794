<template>
  <div>
    <PageHeader :schema="$schemas.subscription" />
    <PageContent>
      <DynamicListPage
        ref="DynamicListPage"
        :schema="$schemas.subscription"
        :service="$api.subscriptions"
        :slots="slotsItems"
        @actionMoreActions="actionMoreActions"
        :localItems.sync="tableItems"
      >
        <template v-slot:[`plan_price`]="{ props: { item } }">
          {{ getPlanValue(item) }}
        </template>

        <template v-slot:[`discount`]="{ props: { item } }">
          {{ getDiscount(item) }}
        </template>

        <template v-slot:[`price_with_discount`]="{ props: { item } }">
          {{ getPriceWithDiscount(item) }}
        </template>
      </DynamicListPage>

      <Row class="mt-10">
        <Col md="6">
          <h2 class="font-weight-black">
            Mais eficiência na sua rotina com o plano ideal para alavancar o seu
            negócio
          </h2>
          <h4 class="mt-3">
            Mais eficiência na sua rotina com o plano ideal para alavancar o seu
            negócio Simplifique a operação da sua empresa com um sistema de
            gestão prático para o seu melhor desempenho.
          </h4>
        </Col>
        <Col md="2"></Col>
        <Col md="2">
          <Card style="border: 1px solid #ccc">
            <div class="d-flex flex-column align-center">
              <h2 style="color: #000">Mensal | Anual</h2>
              <CustomSwitch v-model="period" inset @click="setPeriod" />
              <span style="text-align: center"
                >Com os nossos planos anuais seu negócio economiza muito
                mais</span
              >
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col v-for="(plan, index) in plans" :key="index">
          <div class="d-flex flex-column align-center mt-10">
            <Card min-width="250px" style="border: 1px solid #ccc">
              <h2
                v-html="getPlanName(plan.name)"
                class="font-weight-bold"
                style="color: #000"
              />
              <h4 v-text="plan.description" />
              <h1 class="font-weight-black mt-5" style="color: #000">
                {{ utils.formatCurrency(plan.price) }}
                <span class="font-weight-regular text-h6" style="color: #999"
                  >/{{ getPeriodFormatted(plan.period) }}</span
                >
              </h1>
              <div class="d-flex flex-column align-center mt-5">
                <Button
                  :label="getActionLabel(plan)"
                  rounded
                  :color="getActionColor(plan)"
                  large
                />
              </div>
              <Divider class="my-5" />
              <div
                v-for="(planModule, index) in plan.plan_modules"
                :key="index"
                class=""
                style="color: #000"
              >
                <div class="d-flex align-center mt-2">
                  <Icon :icon="$icons.plusOutline" dense class="mr-3" />
                  <span v-text="`${planModule.description}`" class="body-1" />
                </div>

                <div
                  v-for="(
                    planModuleSetting, index
                  ) in planModule.plan_module_settings"
                  :key="index"
                  class="d-flex align-center mt-2"
                  style="color: #000"
                >
                  <div class="d-flex align-center ml-5">
                    <Icon
                      :icon="
                        planModuleSetting.has ? $icons.check : $icons.close
                      "
                      dense
                      class="mr-3"
                      :color="planModuleSetting.has ? 'success' : 'primary'"
                    />
                    <span
                      v-text="`${planModuleSetting.label}`"
                      class="caption"
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </Col>
      </Row>

      <Dialog :dialog="dialogModal" no-title :maxWidth="modalProps.maxWidth">
        <component
          :is="componentModal"
          slot="content"
          v-bind="modalProps"
          @close="closeModal"
        />
      </Dialog>
    </PageContent>
  </div>
</template>

<script>
/*eslint-disable indent*/
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import {
  Row,
  Col,
  CustomSwitch,
  Card,
  Button,
  Divider,
  Icon,
  Dialog,
} from '@/components/vuetify';
import CreateSubscription from './components/CreateSubscription';
import CyclesSubscription from './components/CyclesSubscription';
import { formatCurrency, getOwnerResellerId } from '@/utils';

const PERIOD = Object.freeze({
  monthly: 'monthly',
  annual: 'annual',
});

export default {
  name: 'ListSubscriptions',
  components: {
    PageHeader,
    PageContent,
    DynamicListPage,
    Row,
    Col,
    CustomSwitch,
    Card,
    Button,
    Divider,
    Icon,
    Dialog,
  },
  data() {
    return {
      dialogModal: false,
      utils: {
        formatCurrency,
      },
      componentModal: null,
      period: false,
      periodHint: 'Mensal',
      plans: [],
      planSelected: {},
      subscriptionSelected: {},
      modalProps: {},
      ownerReseller: {
        plan_prices: [],
      },
      slotsItems: [
        { field: 'plan_price' },
        { field: 'discount' },
        { field: 'price_with_discount' },
      ],
      tableItems: [],
    };
  },
  computed: {
    ownerResellerId() {
      return getOwnerResellerId();
    },
  },
  methods: {
    actionMoreActions(data) {
      const {
        dataListPropsItem: { item },
        moreActionItem,
      } = data;
      this[moreActionItem.action](item);
    },
    setPeriod() {
      this.period = !this.period;

      if (this.ownerResellerId) {
        this.period
          ? this.getOwnerReseller(PERIOD.annual)
          : this.getOwnerReseller(PERIOD.monthly);
      } else {
        this.period
          ? this.getPlans(PERIOD.annual)
          : this.getPlans(PERIOD.monthly);
      }
    },
    async getPlans(period = PERIOD.monthly) {
      this.$api.plans
        .index({
          paginate: { page: 1, perPage: 10 },
          sort: { sortBy: 'order', sortDesc: 'asc' },
        })
        .then(({ data }) => {
          this.plans = data.plans.filter(
            (plan) => plan.period === period && plan.label !== 'free'
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getIcon(label) {
      let icon = '';

      switch (label) {
        case 'user':
          icon = this.$icons.users;
          break;
        case 'nfe':
          icon = this.$icons.fileDocumentCheckOutline;
          break;
        case 'nfce':
          icon = this.$icons.receiptTextCheckOutline;
          break;
        case 'products':
          icon = this.$icons.box;
          break;
      }

      return icon;
    },
    getActionLabel(plan) {
      if (this.isAssigned(plan)) {
        return 'Seu Plano';
      }

      return 'Mudar de Plano';
    },
    getActionColor(plan) {
      if (this.isAssigned(plan)) {
        return 'primary';
      }

      return 'secondary';
    },
    isAssigned(plan) {
      return this.tableItems.subscriptions[0].plan_id === plan.id;
    },
    openModalCreateSubscription(plan) {
      this.componentModal = CreateSubscription;
      this.modalProps = {
        planSelected: plan,
        maxWidth: 750,
      };
      this.planSelected = plan;
      this.dialogModal = true;
    },
    closeModal() {
      this.componentModal = null;
      this.planSelected = {};
      this.subscriptionSelected = {};
      this.modalProps = {};
      this.dialogModal = false;
      this.$refs.DynamicListPage.index();
    },
    listCycles(item) {
      this.componentModal = CyclesSubscription;
      this.modalProps = {
        subscriptionSelected: item,
        maxWidth: 1100,
      };
      this.subscriptionSelected = item;
      this.dialogModal = true;
    },
    getPeriodFormatted(period) {
      switch (period) {
        case 'monthly':
          return 'mês';
        case 'annual':
          return 'ano';
      }
    },
    getOwnerReseller(period = PERIOD.monthly) {
      this.$api.resellers
        .show(this.ownerResellerId)
        .then(({ reseller }) => {
          this.ownerReseller = reseller;
          this.plans = this.ownerReseller.plan_prices
            .filter(
              (planPrice) =>
                planPrice.plan.period === period &&
                planPrice.plan.label !== 'free'
            )
            .map((planPrice) => {
              return {
                ...planPrice.plan,
                price: planPrice.price,
                order: planPrice.plan.order,
              };
            })
            .sort((a, b) => a.order - b.order);
        })
        .catch(() => {});
    },
    getPlanName(name) {
      return `${name} <span style="color: red; font-size: 12px">(${
        this.period ? 'ano' : 'mês'
      })</span>`;
    },
    getPriceWithDiscount(item) {
      return formatCurrency(item.price_with_discount);
    },
    getPlanValue(item) {
      const planPrice = this.getPlanPrice(item);

      return formatCurrency(planPrice?.price);
    },
    getDiscount(item) {
      const planPrice = this.getPlanPrice(item);

      return formatCurrency(
        parseFloat(planPrice?.price) - parseFloat(item.price_with_discount)
      );
    },
    getPlanPrice(item) {
      const planIdCurrent = item.plan_id;

      return this.ownerReseller?.plan_prices?.find(
        (planPrice) => planPrice.plan_id === planIdCurrent
      );
    },
  },
  async mounted() {
    if (this.ownerResellerId) {
      await this.getOwnerReseller();
    } else {
      await this.getPlans();
    }
  },
};
</script>
