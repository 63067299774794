import ForbiddenSchema from './schemas/ForbiddenSchema';
import Forbidden from './pages/Forbidden';

export default [
  {
    path: ForbiddenSchema.routes.forbidden.path,
    name: ForbiddenSchema.routes.forbidden.name,
    component: Forbidden,
    meta: ForbiddenSchema.routes.forbidden.meta,
  },
];
