export default {
  domain: '',
  title: 'Página Inicial',
  description: '',
  createBtn: '',
  routes: {
    home: {
      name: 'home',
      path: '/',
      meta: {
        rule: 'manager',
        subscription: 'premium',
        requiresAuth: true,
      },
    },
  },
};
