<template>
  <div class="mt-10">
    <PageHeader :schema="$schemas.forbidden" />
    <PageContent>
      <Row>
        <Col class="d-flex flex-column align-center w-100 mt-10">
          <Picture :src="require('@/assets/forbidden.svg')" width="400" />
          <h3 class="mt-10">
            Ops! Você não tem permissão para acessar está página.
          </h3>
        </Col>
      </Row>
    </PageContent>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import { Row, Col, Picture } from '@/components/vuetify';

export default {
  name: 'Forbidden',
  components: { PageHeader, PageContent, Row, Col, Picture },
  props: {},
  data() {
    return {};
  },
  mounted() {},
};
</script>
