import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import CustomerSchema from '../schemas/CustomerSchema';
import { formatPhone } from '@/utils';
import { typePage } from '@/utils/enums';

const formatResponse = (item, type = typePage.LIST) => {
  if (type == typePage.SHOW) {
    item;
  }

  const formatList = (item) => {
    item.cell_phone = formatPhone(item.cell_phone);
  };

  if (type === typePage.LIST) formatList(item);
};

const formatResponseGetCustomers = (customers) => {
  return customers.map((customer) => {
    return {
      ...customer,
      cell_phone: formatPhone(customer.cell_phone)
    };
  });
};

const CustomersService = DynamicService('customers', CustomerSchema, { formatResponse });

const CustomersCommands = () => ({
  async getCustomers(resellerId, syncOptions) {
    const { page, itemsPerPage } = syncOptions;
    const { data } = await axios.get(`filters?domain=customer&search_global=true&filter[reseller_id]=${resellerId}&page=${page || 1}&per_page=${itemsPerPage || 6}`);
    const customers = formatResponseGetCustomers(data.customers);

    return {
      ...data,
      customers
    };
  },
  async getAllCompaniesByCustomerId(customerId) {
    const { data: { companies } } = await axios.get(`customers/${customerId}/companies`);

    return companies;
  },
});

export default CustomersService;
export { CustomersService, CustomersCommands };
