<template>
  <div class="totalizers mt-5">
    <Row>
      <Col :cols="classCols">
        <Alert color="primary">
          <h3>Produtos</h3>
          <h4 v-text="totalProducts" />
        </Alert>
      </Col>

      <Col :cols="classCols">
        <Alert color="primary">
          <h3>Frete + Desp.</h3>
          <h4 v-text="totalFreightExpense" />
        </Alert>
      </Col>

      <Col :cols="classCols">
        <Alert color="primary">
          <h3>Impostos</h3>
          <h4 v-text="totalTaxes" />
        </Alert>
      </Col>

      <Col :cols="classCols">
        <Alert color="primary">
          <h3>Descontos</h3>
          <h4 v-text="totalDiscounts" />
        </Alert>
      </Col>

      <Col :cols="classCols">
        <Alert color="primary">
          <h3>Troca</h3>
          <h4 v-text="totalValueReplacement" />
        </Alert>
      </Col>

      <Col :cols="classCols">
        <Alert color="primary">
          <h3>Total</h3>
          <h4 v-text="totalOrder" />
        </Alert>
      </Col>
    </Row>
  </div>
</template>

<script>
import Alert from '@/components/vuetify/Alert';
import Row from '@/components/vuetify/Row';
import Col from '@/components/vuetify/Col';
import UpdateInfosOrderSaleMixin from '../mixins/UpdateInfosOrderSaleMixin';
import { formatCurrency } from '@/utils';

export default {
  name: 'OrderTotalizers',
  components: { Alert, Row, Col },
  mixins: [UpdateInfosOrderSaleMixin],
  computed: {
    formatCurrency () {
      return formatCurrency;
    },
    totalProducts () {
      return formatCurrency(this.order.total_products) || 'R$0,00';
    },
    totalFreightExpense () {
      const total = parseFloat(this.order.total_freight) +
        parseFloat(this.order.total_expense);

      return formatCurrency(total) || 'R$0,00';
    },
    totalTaxes () {
      return formatCurrency(this.order.total_taxes) || 'R$0,00';
    },
    totalDiscounts () {
      return formatCurrency(this.order.total_discount_value) || 'R$0,00';
    },
    totalValueReplacement() {
      return formatCurrency(this.order.total_value_replacement) || 'R$0,00';
    },
    totalOrder () {
      return formatCurrency(this.order.total_order) || 'R$0,00';
    },
  }
};
</script>

<style lang="scss" scoped>
.totalizers {
  .item {
    min-width: 250px;
    margin-right: 15px;
  }
}
</style>
