import { typePageOptions } from '@/utils';
import { text, users, list } from '@/utils/icons';
import { required } from '@/utils/rules';
import { typeUser } from '@/utils/options';
import UserBusiness from '@/business/UserBusiness';

export default {
  domain: 'users',
  domainSingle: 'user',
  business: UserBusiness,
  title: 'Usuários',
  description: 'Lista de todos os usuários cadastrados no sistema',
  createBtn: null,
  routes: {
    list: {
      name: 'settings-users',
      path: '/configuracoes/usuarios',
      meta: { typePage: typePageOptions.list, requiresAuth: true, }
    },
    create: {
      name: 'settings-user-create',
      path: '/configuracoes/usuarios/novo',
      meta: { typePage: typePageOptions.create, requiresAuth: true, }
    },
    show: {
      name: 'settings-user-show',
      path: '/configuracoes/usuarios/:id',
      meta: { typePage: typePageOptions.show, requiresAuth: true, }
    }
  },
  listActions: {
    has: true,
    noShow: true,
    noDestroy: true,
    more: {
      has: false,
      items: []
    },
  },
  filters: {
    has: false,
    items: [
      {
        type: 'text',
        name: 'name',
        label: 'Nome',
        icon: text,
        md: 6,
      },
      {
        type: 'text',
        name: 'email',
        label: 'Email',
        icon: text,
        md: 6,
      },
      {
        type: 'select',
        name: 'type',
        label: 'Tipo Usuário',
        icon: list,
        md: 6,
        itemText: 'text',
        itemValue: 'value',
        items: {
          data: typeUser
        }
      },
    ]
  },
  fields: [
    {
      title: 'Dados do Usuário',
      icon: users,
      openGroup: true,
      items: [
        {
          type: 'text',
          name: 'name',
          formattedName: 'name',
          label: 'Nome',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          counter: 100,
        },
        {
          type: 'text',
          name: 'email',
          formattedName: 'email',
          label: 'Email',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: {
            create: false,
            update: true,
          },
          disabled: false,
        },
        {
          type: 'select',
          name: 'type',
          formattedName: 'type_formatted',
          label: 'Tipo Usuário',
          align: '',
          md: '4',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
          items: typeUser,
          itemText: 'text',
          itemValue: 'value'
        },
        {
          type: 'password',
          name: 'password',
          formattedName: 'password',
          label: 'Senha',
          align: '',
          md: '4',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
        },
        {
          type: 'password',
          name: 'password_confirmation',
          formattedName: 'password_confirmation',
          label: 'Confirmar Senha',
          align: '',
          md: '4',
          rules: [required],
          list: false,
          readonly: false,
          disabled: false,
        },
      ],
    },
  ],
  footer: []
};
