<template>
  <div>
    <ExpansionPanel v-model="panel" :title="l.title" :icon="$icons.box" readonly>
      <v-form v-model="validFormFreight" ref="validFormFreight" lazy-validation>
        <Row>
          <Col :cols="classCols">
            <Select v-model="order.freight" :placeholder="l.form.freight.placeholder"
              :label="l.form.freight.label" :items="propsComponents.freights"
              :readonly="isDelivered" :rules="[rules.required]"
              :loading="propsComponents.loadingSelects" @change="changeFreight" />
          </Col>
          <Col :cols="classCols">
            <TextFieldMoney v-model="order.total_freight" :placeholder="l.form.total_freight.placeholder"
              :label="l.form.total_freight.label" @input="updateInfosOrderSale"
              :readonly="isDelivered" />
          </Col>
          <Col :cols="classCols">
            <TextFieldMoney v-model="order.total_expense" :placeholder="l.form.total_expense.placeholder"
              :label="l.form.total_expense.label" @input="updateInfosOrderSale"
              :readonly="isDelivered" />
          </Col>
          <Col :cols="classCols">
            <CheckBox v-model="order.include_total_in_freight" @setCheckBox="setTotalInFreight"
              :label="l.form.include_total_in_freight.label" style="margin-top: 4px"
              :readonly="isDelivered" />
          </Col>
        </Row>
      </v-form>
    </ExpansionPanel>
  </div>
</template>

<script>
import ExpansionPanel from '@/components/vuetify/ExpansionPanel';
import Row from '@/components/vuetify/Row';
import Col from '@/components/vuetify/Col';
import Select from '@/components/vuetify/Select';
import TextFieldMoney from '@/components/vuetify/TextFieldMoney';
import CheckBox from '@/components/vuetify/CheckBox';
import { required } from '@/utils/rules';
import UpdateInfosOrderSaleMixin from '../mixins/UpdateInfosOrderSaleMixin';

export default {
  name: 'OrderFreight',
  components: { ExpansionPanel, Select, Row, Col, TextFieldMoney, CheckBox },
  inject: ['propsComponents'],
  mixins: [UpdateInfosOrderSaleMixin],
  data () {
    return {
      panel: [0],
      validFormFreight: false,
      rules: {
        required: required
      }
    };
  },
  computed: {
    l () {
      return this.$locales.pt.orders.create.freight;
    },
  },
  methods: {
    setTotalInFreight (value) {
      this.order.include_total_in_freight = value || false;
      this.updateInfosOrderSale();
    },
    changeFreight () {
      if (this.order.freight === this.$enums.freights.NO_FREIGHT ||
        this.order.freight === this.$enums.freights.OWN_ISSUER ||
        this.order.freight === this.$enums.freights.OWN_RECIPIENT) {
        this.resetTransporter();
      }
    }
  }
};
</script>
