var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{class:[
    'content-sidebar',
    _vm.$store.getters.getDarkMode.value
      ? 'side-bar-dark-mode'
      : 'side-bar-no-dark-mode' ],attrs:{"absolute":_vm.height < 500,"permanent":_vm.height >= 500,"elevation":"1"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"color":"#ffffff"}},[_vm._v(_vm._s(_vm.user.name))]),_c('v-list-item-subtitle',[_c('Chip',{attrs:{"label":"Ativo","x-small":"","color":"success"}})],1)],1)],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-card',{class:[
      'mx-auto content-sidebar',
      _vm.$store.getters.getDarkMode.value
        ? 'side-bar-active-dark-mode'
        : 'side-bar-active-no-dark-mode' ],staticStyle:{"height":"100%"},attrs:{"elevation":"0","tile":""}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.items),function(item){return _c('v-list-group',{key:item.title,staticClass:"mt-2",attrs:{"no-action":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"color":"#ffffff"},attrs:{"title":item.subtitle}},[_c('Icon',{staticClass:"mr-1 icon-menu",attrs:{"icon":_vm.$icons[item.icon],"dense":""}}),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},[_vm._l((item.items),function(child){return _c('v-list-item',{key:child.title,class:[
            _vm.getClassMenuActive(child.active),
            _vm.$store.getters.getDarkMode.value
              ? 'side-bar-dark-mode'
              : 'side-bar-no-dark-mode' ],attrs:{"link":""},on:{"click":function($event){return _vm.menuNavigator(item, child)}}},[_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"color":"primary","prepend-icon":item.icon}},[_c('div',{staticClass:"icon-content"},[_c('span',{staticClass:"left-content"},[_c('Icon',{staticClass:"mr-1 icon-menu",attrs:{"icon":_vm.$icons[child.icon],"dense":""}}),_vm._v(" "+_vm._s(child.title)+" ")],1)])])],1)],1)}),_c('hr')],2)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }