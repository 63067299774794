import AuthSchema from '@/views/Auth/schemas/AuthSchema';
import CustomerSchema from '@/views/Settings/Customers/schemas/CustomerSchema';
import CompanySchema from '@/views/Settings/Companies/schemas/CompanySchema';
import CompanySettingSchema from '@/views/Settings/CompanySettings/schemas/CompanySettingSchema';
import SubscriptionSchema from '@/views/Settings/Subscriptions/schemas/SubscriptionSchema';
import NatureOperationSchema from '@/views/Settings/NatureOperations/schemas/NatureOperationSchema';
import InvoiceSettingSchema from '@/views/Settings/InvoiceSettings/schemas/InvoiceSettingSchema';
import HomeSchema from '@/views/Home/Dashboard/schemas/HomeSchema';
import ForbiddenSchema from '@/views/Home/Forbidden/schemas/ForbiddenSchema';
import ResellerSchema from '@/views/Home/Resellers/schemas/ResellerSchema';
import UserSchema from '@/views/Settings/Users/schemas/UserSchema';
import AllTypeSchema from '@/views/AllTypes/schemas/AllTypeSchema';
import RegisterSchema from '@/views/GeneralRegisters/Registers/schemas/RegisterSchema';
import VehicleSchema from '@/views/GeneralRegisters/Registers/schemas/VehicleSchema';
import TypeInstallmentSchema from '@/views/GeneralRegisters/TypeInstallments/schemas/TypeInstallmentSchema';
import AccountingAccountSchema from '@/views/GeneralRegisters/AccountingAccounts/schemas/AccountingAccountSchema';
import CostCenterSchema from '@/views/GeneralRegisters/CostCenters/schemas/CostCenterSchema';
import StockSchema from '@/views/Products/Stocks/schemas/StockSchema';
import VariationSchema from '@/views/Products/Variations/schemas/VariationSchema';
import ProductSchema from '@/views/Products/Products/schemas/ProductSchema';
import PriceListSchema from '@/views/Products/PriceLists/schemas/PriceListSchema';
import StockMovementSchema from '@/views/Products/StockMovements/schemas/StockMovementSchema';
import ProductVariationStockMovementSchema from '@/views/Products/ProductMovements/schemas/ProductVariationStockMovementSchema';
import BalanceSchema from '@/views/Products/Balances/schemas/BalanceSchema';
import OrderSchema from '@/views/Movements/Orders/schemas/OrderSchema';
import OrderServiceSchema from '@/views/Movements/OrderServices/schemas/OrderServiceSchema';
import TableSchema from '@/views/Operations/Tables/schemas/TableSchema';
import AccountSchema from '@/views/Financial/Accounts/schemas/AccountSchema';
import MovementSchema from '@/views/Financial/Movements/schemas/MovementSchema';
import BankSchema from '@/views/Financial/Banks/schemas/BankSchema';
import BoxSchema from '@/views/Financial/Boxes/schemas/BoxSchema';
import OpenTitleSchema from '@/views/Financial/OpenTitles/schemas/OpenTitleSchema';
import CreditCardAdministratorSchema from '@/views/Financial/CreditCardAdministrators/schemas/CreditCardAdministratorSchema';
import CreditCardControlSchema from '@/views/Financial/CreditCardControls/schemas/CreditCardControlSchema';
import CheckControlSchema from '@/views/Financial/CheckControls/schemas/CheckControlSchema';
import BilletControlSchema from '@/views/Financial/BilletControls/schemas/BilletControlSchema';
import CreditLetterSchema from '@/views/Financial/CreditLetters/schemas/CreditLetterSchema';
import ProductInvoiceSchema from '@/views/TaxOperations/ProductInvoices/schemas/ProductInvoiceSchema';
import ServiceInvoiceSchema from '@/views/TaxOperations/ServiceInvoices/schemas/ServiceInvoiceSchema';
import CashFlowSchema from '@/views/Planning/CashFlows/schemas/CashFlowSchema';
import ReportSchema from '@/views/Reports/schemas/ReportSchema';

const schemas = Object.freeze({
  auth: AuthSchema,
  customer: CustomerSchema,
  company: CompanySchema,
  companySetting: CompanySettingSchema,
  subscription: SubscriptionSchema,
  natureOperation: NatureOperationSchema,
  invoiceSetting: InvoiceSettingSchema,
  home: HomeSchema,
  forbidden: ForbiddenSchema,
  reseller: ResellerSchema,
  user: UserSchema,
  register: RegisterSchema,
  vehicle: VehicleSchema,
  typeInstallment: TypeInstallmentSchema,
  accountingAccount: AccountingAccountSchema,
  costCenter: CostCenterSchema,
  allType: AllTypeSchema,
  stock: StockSchema,
  variation: VariationSchema,
  product: ProductSchema,
  priceList: PriceListSchema,
  stockMovement: StockMovementSchema,
  productVariationStockMovement: ProductVariationStockMovementSchema,
  balance: BalanceSchema,
  order: OrderSchema,
  orderService: OrderServiceSchema,
  table: TableSchema,
  account: AccountSchema,
  financialMovement: MovementSchema,
  bank: BankSchema,
  box: BoxSchema,
  openTitle: OpenTitleSchema,
  creditCardAdministrator: CreditCardAdministratorSchema,
  creditCardControl: CreditCardControlSchema,
  checkControl: CheckControlSchema,
  billetControl: BilletControlSchema,
  creditLetter: CreditLetterSchema,
  productInvoice: ProductInvoiceSchema,
  serviceInvoice: ServiceInvoiceSchema,
  cashFlow: CashFlowSchema,
  report: ReportSchema,
});

export default {
  install: function (Vue) {
    Vue.prototype.$schemas = schemas;
    Vue.schemas = schemas;
  }
};
