<template>
  <div>
    <PageHeader :schema="$schemas.order" :create-route-name="createRouteName"
      :create-btn="createBtn" :titleCustom="title" :descriptionCustom="description" />
    <PageContent>
      <DynamicListPage ref="DynamicListPage" fixed-filter :schema="$schemas.order"
        :service="$api.orders" :fixedFilterParams="fixedFilterParams"
        :slots="slotsItems" :key="keyComponent"
        @actionMoreActions="actionMoreActions" @devolutionOrder="devolutionOrder"
        @reverseOrder="reverseOrder">

        <template v-slot:total_discount_value_formatted="{ props: { item } }">
          <span style="color: #F00">-{{item.total_discount_value_formatted}}</span>
        </template>
        <template v-slot:total_taxes_formatted="{ props: { item } }">
          <span style="color: #00F">+{{item.total_taxes_formatted}}</span>
        </template>
        <template v-slot:total_freight_formatted="{ props: { item } }">
          <span style="color: #00F">+{{item.total_freight_formatted}}</span>
        </template>
        <template v-slot:total_expense_formatted="{ props: { item } }">
          <span style="color: #00F">+{{item.total_expense_formatted}}</span>
        </template>
        <!-- <template v-slot:is_pdv="{ props: { item } }">
          <Chip v-if="item.is_pdv" label="PDV" small color="purple" dark />
        </template>
        <template v-slot:is_wholesale="{ props: { item } }">
          <Chip :label="getTypeSale(item)" small :color="colorsTypeSale(item)" />
        </template>-->
        <template v-slot:is_delivered="{ props: { item } }">
          <Chip :label="getStatus(item)" small :color="colorsStatus(item)" />
        </template>
      </DynamicListPage>
    </PageContent>

    <Dialog :dialog="dialogModal" no-title :maxWidth="modalProps.maxWidth">
      <component :is="componentModal" slot="content" v-bind="modalProps" v-on="eventsModal"
        @update:dialogModal="closeModal" />
    </Dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageContent from '@/components/PageContent';
import DynamicListPage from '@/components/dynamics/DynamicListPage';
import { kebabCase, snakeCase } from 'lodash';
import { Dialog, Chip } from  '@/components/vuetify';
import GenerateProductInvoice from '../components/listOrders/GenerateProductInvoice';
import GeneratePDFMixin from '@/mixins/GeneratePDFMixin.vue';
import GetCompanySettingsMixin from '@/mixins/GetCompanySettingsMixin';
import { getCompanySettings, randomNumber } from '@/utils';
import DuplicateOrderConfirmation from '../components/listOrders/DuplicateOrderConfirmation';

const CREATE_BTN = {
  'order-sale-create': 'Nova Venda',
  'order-purchase-create': 'Nova Compra',
  'order-budget-create': 'Novo Orçamento',
  'order-sale-devolution-create': 'Nova Devolução de Venda',
  'order-purchase-devolution-create': 'Nova Devolução de Compra',
};

export default {
  name: 'ListOrderSales',
  components: { PageHeader, PageContent, DynamicListPage, Chip, Dialog },
  data () {
    return {
      slotsItems: [
        { field: 'is_pdv' },
        { field: 'is_wholesale' },
        { field: 'is_delivered' },
        { field: 'total_discount_value_formatted' },
        { field: 'total_taxes_formatted' },
        { field: 'total_freight_formatted' },
        { field: 'total_expense_formatted' },
      ],
      dialogModal: false,
      componentModal: null,
      modalProps: {},
      eventsModal: {},
      orderSelected: {},
      keyComponent: 0,
      invoiceSettings: [],
      companySetting: {},
    };
  },
  computed: {
    l () {
      return this.$locales.pt.orders.list;
    },
    createRouteName () {
      return this.$schemas.order.routes[this.$route.meta.type].create?.name;
    },
    createBtn () {
      return CREATE_BTN[this.$schemas.order.routes[this.$route.meta.type].create?.name];
    },
    fixedFilterParams () {
      return { name: 'type', value: kebabCase(this.$route.meta.type) };
    },
    title () {
      return this.$route.meta.title;
    },
    description () {
      return this.$route.meta.description;
    }
  },
  watch: {
    title () {
      this.keyComponent++;
    }
  },
  mixins: [GeneratePDFMixin, GetCompanySettingsMixin],
  methods: {
    actionMoreActions (data) {
      const { dataListPropsItem: { item }, moreActionItem } = data;
      this[moreActionItem.action](item);
    },
    getStatus(item) {
      return this.l.situation[item.status];
    },
    colorsStatus(item) {
      switch (item.status) {
      case 'pending':
        return 'warning';
      case 'finished':
        return 'success';
      case 'canceled':
        return 'error';
      case 'returned':
        return 'accent';
      case 'reversed':
        return 'primary';
      default:
        return 'info';
      }
    },
    getTypeSale(item) {
      return item.is_wholesale ? 'Atacado' : 'Varejo';
    },
    colorsTypeSale(item) {
      return item.is_wholesale ? 'orange' : 'info';
    },
    async generateInvoice (item) {
      this.orderSelected = item;
      this.$api.invoiceSettings.index().then((res) => {
        const { data: { invoice_settings } } = res;
        this.invoiceSettings = invoice_settings;
        let invoiceSettingSelected = {
          model: 55,
          series: 1,
          current_number: 0
        };

        const default_invoice_setting_id = getCompanySettings()[`${snakeCase(this.orderSelected?.type)}_invoice_setting_id`];

        if (default_invoice_setting_id) {
          invoiceSettingSelected = this.invoiceSettings.find((invoiceSetting) => invoiceSetting.id === default_invoice_setting_id);
        } else if (!default_invoice_setting_id && this.invoiceSettings.length === 1) {
          invoiceSettingSelected = this.invoiceSettings[0];
        }

        this.componentModal = GenerateProductInvoice;
        this.dialogModal = !this.dialogModal;
        this.modalProps = {
          key: randomNumber(),
          maxWidth: 1100,
          order: this.orderSelected,
          invoiceSettings: this.invoiceSettings,
          invoiceSettingSelected: invoiceSettingSelected
        };
      }).catch(() => {
        this.$noty.error('Erro ao buscar configurações de nota fiscal');
      });
    },
    showInvoice (item) {
      this.$router.push({
        name: this.$schemas.productInvoice.routes.show.name,
        params: {
          id: item.product_invoice_id,
        },
      });
    },
    print (item) {
      const { id } = item;
      this.generatePDF(this.$schemas.order.domain, 'print-order-details?type=details', { id });
    },
    printCoupon (item) {
      const { id } = item;
      this.generatePDFToPrint(this.$schemas.order.domain, 'print-order-details?type=coupon', { id });
    },
    download (item) {
      const { id } = item;
      this.generatePDF(this.$schemas.order.domain, 'print-order-details', { id }, true);
    },
    closeModal() {
      this.dialogModal = false;
      this.$refs.DynamicListPage.fetchDataApi();
    },
    duplicateOrder(item) {
      this.orderSelected = item;
      this.componentModal = DuplicateOrderConfirmation;
      this.dialogModal = !this.dialogModal;
      this.modalProps = {
        maxWidth: 600,
        order: this.orderSelected,
      };
    },
    devolutionOrder(data) {
      const payload = {
        nature_operation_id: data.nature_operation_id,
        devolution_type: this.$enums.orderTypes.ORDER_SALE_DEVOLUTION,
        generate_invoice: data.generateInvoice,
        transmit_invoice: data.transmitInvoice,
      };
      this.$api.orders.generateDevolutionOrder(data.order_id, payload)
        .then(() => {
          this.$noty.success('Devolução gerada com sucesso');
          this.$refs.DynamicListPage.fetchDataApi();
        }).catch((err) => {
          if (err.response.status === 400) {
            this.$noty.error(err.response.data.error);
          } else {
            this.$noty.error('Erro ao gerar devolução');
          }
        }).finally(() => {
          this.$refs.DynamicListPage.closeModalListActions();
        });
    },
    reverseOrder(item) {
      this.$api.orders.reverseOrder(item.id)
        .then(() => {
          this.$refs.DynamicListPage.fetchDataApi();
        }).finally(() => {
          this.$refs.DynamicListPage.closeConfirmDialogMoreActions();
        });
    },
  },
  mounted() {
    const { generateInvoice, order } = this.$route.params;

    if (generateInvoice) {
      this.generateInvoice(order);
    }
  }
};
</script>
