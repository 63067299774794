import AuthService from '@/views/Auth/services/AuthService';
import ResellersService, {
  ResellersCommands,
} from '@/views/Home/Resellers/services/ResellersService';
import CustomersService, {
  CustomersCommands,
} from '@/views/Settings/Customers/services/CustomersService';
import CompaniesService, {
  CompaniesCommands,
} from '@/views/Settings/Companies/services/CompaniesService';
import CompanySettingsService, {
  CompanySettingsCommands,
} from '@/views/Settings/CompanySettings/services/CompanySettingsService';
import SubscriptionsService, {
  SubscriptionsCommands,
} from '@/views/Settings/Subscriptions/services/SubscriptionsService';
import PlansService from '@/views/Settings/Plans/services/PlansService';
import NatureOperationsService, {
  NatureOperationStatesCommands,
} from '@/views/Settings/NatureOperations/services/NatureOperationsService';
import InvoiceSettingsService, {
  InvoiceSettingsCommands,
} from '@/views/Settings/InvoiceSettings/services/InvoiceSettingsService';
import UsersService from '@/views/Settings/Users/services/UsersService';
import AllTypesService from '@/views/AllTypes/services/AllTypesService';
import RegistersService from '@/views/GeneralRegisters/Registers/services/RegistersService';
import VehiclesService from '@/views/GeneralRegisters/Registers/services/VehiclesService';
import TypeInstallmentsService from '@/views/GeneralRegisters/TypeInstallments/services/TypeInstallmentsService';
import AccountingAccountsService from '@/views/GeneralRegisters/AccountingAccounts/services/AccountingAccountsService';
import CostCentersService from '@/views/GeneralRegisters/CostCenters/services/CostCentersService';
import StocksService, {
  StocksCommands,
} from '@/views/Products/Stocks/services/StocksService';
import VariationsService from '@/views/Products/Variations/services/VariationsService';
import VariationValuesService, {
  VariationValuesCommands,
} from '@/views/Products/Variations/services/VariationValuesService';
import ProductsService, {
  ProductsCommands,
} from '@/views/Products/Products/services/ProductsService';
import ProductVariationsService, {
  ProductVariationsCommands,
} from '@/views/Products/Products/services/ProductVariationsService';
import PriceListsService, {
  PriceListsCommands,
} from '@/views/Products/PriceLists/services/PriceListsService';
import StockMovementsService from '@/views/Products/StockMovements/services/StockMovementsService';
import ProductVariationStockMovementsService from '@/views/Products/ProductMovements/services/ProductVariationStockMovementsService';
import BalancesService, {
  BalancesCommands,
} from '@/views/Products/Balances/services/BalancesService';
import OrdersService, {
  OrdersCommands,
} from '@/views/Movements/Orders/services/OrdersService';
import OrderServicesService from '@/views/Movements/OrderServices/services/OrderServicesService';
import TablesService, {
  TablesCommands,
} from '@/views/Operations/Tables/services/TablesService';
import AccountsService, {
  AccountsCommands,
} from '@/views/Financial/Accounts/services/AccountsService';
import FinancialMovementsService from '@/views/Financial/Movements/services/FinancialMovementsService';
import BanksService, {
  BankMovementsCommands,
} from '@/views/Financial/Banks/services/BanksService';
import BoxesService, {
  BoxMovementsCommands,
} from '@/views/Financial/Boxes/services/BoxesService';
import OpenTitlesService from '@/views/Financial/OpenTitles/services/OpenTitlesService';
import CreditCardAdministratorsService from '@/views/Financial/CreditCardAdministrators/services/CreditCardAdministratorsService';
import CreditCardControlsService, {
  CreditCardControlsCommands,
} from '@/views/Financial/CreditCardControls/services/CreditCardControlsService';
import CheckControlsService, {
  CheckControlsCommands,
} from '@/views/Financial/CheckControls/services/CheckControlsService';
import BilletControlsService, {
  BilletControlsCommands,
} from '@/views/Financial/BilletControls/services/BilletControlsService';
import CreditLettersService, {
  CreditLettersCommands,
} from '@/views/Financial/CreditLetters/services/CreditLettersService';
import ProductInvoicesService, {
  ProductInvoicesCommands,
} from '@/views/TaxOperations/ProductInvoices/services/ProductInvoicesService';
import ServiceInvoicesService from '@/views/TaxOperations/ServiceInvoices/services/ServiceInvoicesService';
import CashFlowsService, {
  CashFlowsCommands,
} from '@/views/Planning/CashFlows/services/CashFlowsService';
import ReportsService, {
  ReportsCommands,
} from '@/views/Reports/services/ReportsService';

const api = Object.freeze({
  auth: AuthService,
  customers: { ...CustomersService, ...CustomersCommands() },
  resellers: { ...ResellersService, ...ResellersCommands() },
  companies: { ...CompaniesService, ...CompaniesCommands() },
  companySettings: { ...CompanySettingsService, ...CompanySettingsCommands() },
  subscriptions: { ...SubscriptionsService, ...SubscriptionsCommands() },
  plans: PlansService,
  natureOperations: {
    ...NatureOperationsService,
    ...NatureOperationStatesCommands(),
  },
  invoiceSettings: { ...InvoiceSettingsService, ...InvoiceSettingsCommands() },
  users: UsersService,
  allTypes: AllTypesService,
  registers: RegistersService,
  vehicles: VehiclesService,
  typeInstallments: TypeInstallmentsService,
  accountingAccounts: AccountingAccountsService,
  costCenters: CostCentersService,
  stocks: { ...StocksService, ...StocksCommands() },
  variations: VariationsService,
  variationValues: { ...VariationValuesService, ...VariationValuesCommands() },
  products: { ...ProductsService, ...ProductsCommands() },
  productVariations: {
    ...ProductVariationsService,
    ...ProductVariationsCommands(),
  },
  priceLists: { ...PriceListsService, ...PriceListsCommands() },
  stockMovements: StockMovementsService,
  productVariationStockMovements: ProductVariationStockMovementsService,
  balances: { ...BalancesService, ...BalancesCommands() },
  orders: { ...OrdersService, ...OrdersCommands() },
  orderServices: OrderServicesService,
  tables: { ...TablesService, ...TablesCommands() },
  accounts: { ...AccountsService, ...AccountsCommands() },
  financialMovements: { ...FinancialMovementsService },
  banks: { ...BanksService, ...BankMovementsCommands() },
  boxes: { ...BoxesService, ...BoxMovementsCommands() },
  openTitles: OpenTitlesService,
  creditCardAdministrators: CreditCardAdministratorsService,
  creditCardControls: {
    ...CreditCardControlsService,
    ...CreditCardControlsCommands(),
  },
  checkControls: { ...CheckControlsService, ...CheckControlsCommands() },
  billetControls: { ...BilletControlsService, ...BilletControlsCommands() },
  creditLetters: { ...CreditLettersService, ...CreditLettersCommands() },
  productInvoices: { ...ProductInvoicesService, ...ProductInvoicesCommands() },
  serviceInvoices: ServiceInvoicesService,
  cashFlows: { ...CashFlowsService, ...CashFlowsCommands() },
  reports: { ...ReportsService, ...ReportsCommands() },
});

export default {
  install: function (Vue) {
    Vue.prototype.$api = api;
    Vue.api = api;
  },
};
