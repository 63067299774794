<template>
  <div>
    <ExpansionPanel v-model="panel" :title="l.title" :icon="$icons.box" readonly>
      <v-form v-model="validFormTransporter" ref="validFormTransporter" lazy-validation>
        <Row>
          <Col :cols="classCols">
            <Select v-model="order.transporter.register_id" :placeholder="l.form.register.placeholder"
              :label="l.form.register.label" :items="propsComponents.transporters"
              :readonly="isDelivered || noFreight" @change="changeTransporter" :clearable="!isDelivered"
              :loading="propsComponents.loadingSelects" />
          </Col>
          <Col :cols="classCols">
            <TextField v-model="order.transporter.specie" :placeholder="l.form.specie.placeholder"
              :label="l.form.specie.label" :readonly="isDelivered  || noFreight" />
          </Col>
          <Col :cols="classCols">
            <TextField v-model="order.transporter.brand" :placeholder="l.form.brand.placeholder"
              :label="l.form.brand.label" :readonly="isDelivered  || noFreight" />
          </Col>
          <Col :cols="classCols">
            <TextField v-model="order.transporter.cte" :placeholder="l.form.cte.placeholder"
              :label="l.form.cte.label" :readonly="isDelivered  || noFreight" />
          </Col>
        </Row>
        <Row>
          <Col :cols="classCols">
            <TextFieldInteger v-model="order.transporter.quantity"
              :placeholder="l.form.quantity.placeholder"
              :label="l.form.quantity.label" prefix='' :precision="0"
              :readonly="isDelivered || noFreight" />
          </Col>
          <Col :cols="classCols">
            <TextFieldInteger v-model="order.transporter.gross_weight"
              :placeholder="l.form.gross_weight.placeholder"
              :label="l.form.gross_weight.label" prefix='' :precision="0"
              :readonly="isDelivered || noFreight" />
          </Col>
          <Col :cols="classCols">
            <TextFieldInteger v-model="order.transporter.liquid_weight"
              :placeholder="l.form.liquid_weight.placeholder"
              :label="l.form.liquid_weight.label" prefix='' :precision="0"
              :readonly="isDelivered || noFreight" />
          </Col>
          <Col :cols="classCols">
            <Select v-model="order.transporter.vehicle_id"
              :placeholder="l.form.plate.placeholder"
              :label="l.form.plate.label" :items="propsComponents.vehicles"
              return-object :readonly="isDelivered || noFreight"
              :loading="propsComponents.loadingSelects"
              @change="changeVehicle($event)" :clearable="!isDelivered" />
          </Col>
          <Col :cols="classCols">
            <TextField v-model="order.transporter.uf"
              :placeholder="l.form.uf.placeholder"
              :label="l.form.uf.label" readonly />
          </Col>
          <Col :cols="classCols">
            <TextField v-model="order.transporter.rntc"
              :placeholder="l.form.rntc.placeholder"
              :label="l.form.rntc.label" readonly />
          </Col>
        </Row>
      </v-form>
    </ExpansionPanel>
  </div>
</template>

<script>
import {
  ExpansionPanel,
  Row,
  Col,
  Select,
  TextField,
  TextFieldInteger,
} from '@/components/vuetify';
import { required } from '@/utils/rules';
import UpdateInfosOrderSaleMixin from '../mixins/UpdateInfosOrderSaleMixin';

export default {
  name: 'OrderTransporter',
  components: {
    ExpansionPanel,
    Select,
    Row,
    Col,
    TextField,
    TextFieldInteger,
  },
  inject: ['propsComponents'],
  mixins: [UpdateInfosOrderSaleMixin],
  data () {
    return {
      panel: [0],
      validFormTransporter: false,
      rules: {
        required: required
      },
    };
  },
  computed: {
    l () {
      return this.$locales.pt.orders.create.transporter;
    },
    noFreight () {
      return this.order.freight === this.$enums.freights.NO_FREIGHT ||
        this.order.freight === this.$enums.freights.OWN_ISSUER ||
        this.order.freight === this.$enums.freights.OWN_RECIPIENT;
    },
  },
  methods: {
    changeVehicle (data) {
      this.order.transporter.vehicle_id = data ? data.item?.id : null;
      this.order.transporter.uf = data ? data.item?.uf : null;
      this.order.transporter.rntc = data ? data.item?.rntc : null;
      this.order.transporter.plate = data ? data.item?.plate : null;
    },
    changeTransporter () {
      this.order.transporter.gross_weight = 0;
      this.order.transporter.liquid_weight = 0;
      this.order.transporter.quantity = 0;

      if (this.order.transporter.register_id) {
        this.order.items.forEach(item => {
          this.order.transporter.liquid_weight += parseFloat(item.total_weight_liquid);
          this.order.transporter.gross_weight += parseFloat(item.total_weight_gross);
          this.order.transporter.quantity += parseFloat(item.quantity);
        });
      } else {
        this.resetTransporter();
      }
    }
  }
};
</script>
