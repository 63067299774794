var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DynamicListPageHeader',{ref:"dynamicListPageHeader",attrs:{"schema":_vm.schema,"searchChips":_vm.searchChips,"itemsSelectsFilters":_vm.itemsSelectsFilters},on:{"searchItems":_vm.fetchDataApi,"closeChip":_vm.closeChip}}),(!_vm.noResults)?_c('div',[_c('v-data-table',{staticClass:"elevation-1 dynamic-list-data-table",attrs:{"item-key":"id","dense":_vm.dense,"show-expand":_vm.schema.showExpand,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.localItems[_vm.schema.domain],"options":_vm.options,"server-items-length":_vm.totalLocalItems,"single-select":_vm.schema.singleSelect,"show-select":_vm.schema.checkItem,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"footer-props":{ 'items-per-page-options': [5, 7, 10, 20, 50] }},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"px-1 py-1"},[_c('Tabs',{on:{"setTab":_vm.setTab},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabItems),function(item){return _c('Tab',{key:item},[_vm._v(_vm._s(item))])}),1),_c('TabsItems',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('Row',{staticClass:"my-5"},_vm._l((_vm.expandedItems[_vm.tab]),function(expandedItem,index){return _c('Col',{key:index,attrs:{"cols":"12","xs":"12","sm":"12","md":expandedItem.md || 12,"lg":expandedItem.md || 12}},[_c('span',{staticClass:"d-block font-weight-bold subtitle-1",domProps:{"textContent":_vm._s(((expandedItem.label) + ":"))}}),_c('span',{domProps:{"textContent":_vm._s(
                      _vm.getExpandedFieldValues(item, expandedItem.formattedName)
                    )}})])}),1)],1),_vm._t("content-expanded",null,{"props":{ headers: headers, item: item }})],2)])]}},_vm._l((_vm.slots),function(slot){return {key:("item." + (slot.field)),fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_vm._t(slot.field,null,{"props":{ item: item, index: index }})]}}}),{key:"item.use_nickname_formatted",fn:function(ref){
                    var item = ref.item;
return [_c('Chip',{attrs:{"label":item.use_nickname_formatted,"small":"","color":_vm.colorsUseNickname[item.use_nickname]}})]}},{key:"item.situation_formatted",fn:function(ref){
                    var item = ref.item;
return [_c('Chip',{attrs:{"label":item.situation_formatted,"small":"","color":_vm.colorsSituation[item.situation]}})]}},(!_vm.hasColumnStatusLocal)?{key:"item.status_formatted",fn:function(ref){
                    var item = ref.item;
return [_c('Chip',{attrs:{"label":item.status_formatted,"small":"","color":_vm.colorsStatus[item.status],"dark":""}})]}}:null,{key:"item.actions",fn:function(props){return [_c('ActionsListPage',{attrs:{"schema":_vm.schema,"icons":_vm.icons,"data-list-props":props},on:{"openDialogDestroy":_vm.openDialogDestroy,"actionMoreActions":_vm.actionMoreActions}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',[_vm._t("custom-footer")],2),_c('Row',{staticClass:"mt-5 custom-footer"},_vm._l((_vm.schema.footer),function(item,index){return _c('Col',{key:index,attrs:{"md":item.md}},[_c('Alert',{staticClass:"py-2",attrs:{"color":item.color || 'primary'}},[_c('h5',[_vm._v(_vm._s(item.label))]),_c('h4',[_vm._v(_vm._s(_vm.localItems[item.name]))])])],1)}),1)],1):_c('div',{staticClass:"d-flex flex-column align-center w-100"},[_c('Picture',{attrs:{"src":require('@/assets/no-search.svg'),"width":"400"}}),_c('h3',[_vm._v("Ops! Sem resultados foi encontrado. Tente novamente.")])],1),_c('DialogConfirmation',{attrs:{"dialog":_vm.dialog,"loading":_vm.loadingDestroy},on:{"noActionDialogConfirmation":_vm.clearProps,"yesActionDialogConfirmation":_vm.destroy}}),_c('DialogConfirmation',{attrs:{"dialog":_vm.confirmDialogMoreActions,"loading":_vm.loadingAction,"title":_vm.titleConfirmDialogMoreActions,"message":_vm.messageConfirmDialogMoreActions},on:{"noActionDialogConfirmation":_vm.closeConfirmDialogMoreActions,"yesActionDialogConfirmation":_vm.changeEventConfirmDialogMoreActions}}),_c('Dialog',{attrs:{"dialog":_vm.dialogModalListActions,"no-title":"","maxWidth":_vm.modalPropsListActions.maxWidth}},[_c(_vm.componentModalListActions,_vm._g(_vm._b({tag:"component",attrs:{"slot":"content","loadingAction":_vm.loadingAction},on:{"close":function($event){return _vm.closeModalListActions()}},slot:"content"},'component',_vm.modalPropsListActions,false),_vm.eventsDialogMoreActions))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }