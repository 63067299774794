var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"schema":_vm.$schemas.order,"create-route-name":_vm.createRouteName,"create-btn":_vm.createBtn,"titleCustom":_vm.title,"descriptionCustom":_vm.description}}),_c('PageContent',[_c('DynamicListPage',{key:_vm.keyComponent,ref:"DynamicListPage",attrs:{"fixed-filter":"","schema":_vm.$schemas.order,"service":_vm.$api.orders,"fixedFilterParams":_vm.fixedFilterParams,"slots":_vm.slotsItems},on:{"actionMoreActions":_vm.actionMoreActions,"devolutionOrder":_vm.devolutionOrder,"reverseOrder":_vm.reverseOrder},scopedSlots:_vm._u([{key:"total_discount_value_formatted",fn:function(ref){
var item = ref.props.item;
return [_c('span',{staticStyle:{"color":"#F00"}},[_vm._v("-"+_vm._s(item.total_discount_value_formatted))])]}},{key:"total_taxes_formatted",fn:function(ref){
var item = ref.props.item;
return [_c('span',{staticStyle:{"color":"#00F"}},[_vm._v("+"+_vm._s(item.total_taxes_formatted))])]}},{key:"total_freight_formatted",fn:function(ref){
var item = ref.props.item;
return [_c('span',{staticStyle:{"color":"#00F"}},[_vm._v("+"+_vm._s(item.total_freight_formatted))])]}},{key:"total_expense_formatted",fn:function(ref){
var item = ref.props.item;
return [_c('span',{staticStyle:{"color":"#00F"}},[_vm._v("+"+_vm._s(item.total_expense_formatted))])]}},{key:"is_delivered",fn:function(ref){
var item = ref.props.item;
return [_c('Chip',{attrs:{"label":_vm.getStatus(item),"small":"","color":_vm.colorsStatus(item)}})]}}])})],1),_c('Dialog',{attrs:{"dialog":_vm.dialogModal,"no-title":"","maxWidth":_vm.modalProps.maxWidth}},[_c(_vm.componentModal,_vm._g(_vm._b({tag:"component",attrs:{"slot":"content"},on:{"update:dialogModal":_vm.closeModal},slot:"content"},'component',_vm.modalProps,false),_vm.eventsModal))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }