export const account = 'mdi-account-plus-outline';
export const accountCreditCardOutline = 'mdi-account-credit-card-outline';
export const accountSettings = 'mdi-account-settings-outline';
export const accountSwitch = 'mdi-account-switch';
export const accountTie = 'mdi-account-tie';
export const accountWrench = 'mdi-account-wrench ';
export const address = 'mdi-map-marker-outline';
export const agent = 'mdi-briefcase-account';
export const alarm = 'mdi-alarm';
export const allTypes = 'mdi-arrow-expand-all';
export const archiveArrowDownOutline = 'mdi-archive-arrow-down-outline';
export const arrowLeft = 'mdi-arrow-left-bold';
export const arrowRight = 'mdi-arrow-right-bold';
export const bank = 'mdi-bank';
export const barcode = 'mdi-barcode';
export const box = 'mdi-inbox-multiple';
export const briefcaseAccountOutline = 'mdi-briefcase-account-outline';
export const calendar = 'mdi-calendar-month-outline';
export const calendarEdit = 'mdi-calendar-edit';
export const cancel = 'mdi-cancel';
export const cardAccountDetailsFavoriteOutline =
  'mdi-card-account-details-star-outline';
export const car = 'mdi-car-estate';
export const cashClock = 'mdi-cash-clock';
export const cashMinus = 'mdi-cash-minus';
export const cashPlus = 'mdi-cash-plus';
export const certificate = 'mdi-certificate';
export const certificateOutline = 'mdi-certificate-outline';
export const chart = 'mdi-chart-box-outline';
export const cellPhone = 'mdi-cellphone';
export const check = 'mdi-check-bold';
export const checkbook = 'mdi-checkbook';
export const chevronLeft = 'mdi-chevron-left';
export const chevronRight = 'mdi-chevron-right';
export const clipboardArrowDownOutline = 'mdi-clipboard-arrow-down-outline';
export const clipboardArrowUpOutline = 'mdi-clipboard-arrow-up-outline';
export const clipboardFlowOutline = 'mdi-clipboard-flow-outline';
export const clipboardMinusOutline = 'mdi-clipboard-minus-outline';
export const clipboardPlusOutline = 'mdi-clipboard-plus-outline';
export const clockListOutline = 'mdi-clipboard-text-clock-outline';
export const close = 'mdi-close';
export const cogOutline = 'mdi-cog-outline';
export const companySettings = 'mdi-office-building-cog';
export const copy = 'mdi-content-copy';
export const creditCardSearchOutline = 'mdi-credit-card-search-outline';
export const crown = 'mdi-crown';
export const databaseSearch = 'mdi-database-search';
export const dataRegisters = 'mdi-clipboard-text-outline';
export const destroy = 'mdi-delete';
export const domain = 'mdi-domain';
export const dotsVertical = 'mdi-dots-vertical';
export const download = 'mdi-download-box-outline';
export const edit = 'mdi-pencil';
export const email = 'mdi-email-outline';
export const evPlugType1 = 'mdi-ev-plug-type1';
export const evPlugType2 = 'mdi-ev-plug-type2';
export const facebook = 'mdi-facebook';
export const fileDocumentCheckOutline = 'mdi-file-document-check-outline';
export const finance = 'mdi-finance';
export const financial = 'mdi-cash-register';
export const fileChart = 'mdi-file-chart';
export const filter = 'mdi-filter-menu-outline';
export const google = 'mdi-google-plus';
export const handshakeOutline = 'mdi-handshake-outline';
export const history = 'mdi-history';
export const home = 'mdi-home-alert-outline';
export const industry = 'mdi-factory';
export const keyboardReturn = 'mdi-keyboard-return';
export const list = 'mdi-format-list-bulleted-type';
export const lockOutline = 'mdi-lock-outline';
export const logout = 'mdi-logout';
export const maps = 'mdi-map-marker';
export const menuDown = 'mdi-menu-down';
export const menuUp = 'mdi-menu-up';
export const money = 'mdi-currency-usd';
export const networkPos = 'mdi-network-pos';
export const note = 'mdi-file-send-outline';
export const noteMultiple = 'mdi-note-multiple';
export const numeric = 'mdi-numeric';
export const officeBuildingCogOutline = 'mdi-office-building-cog-outline';
export const openInNew = 'mdi-open-in-new';
export const origin = 'mdi-share-all';
export const pencilBoxMultipleOutline = 'mdi-pencil-box-multiple-outline';
export const phone = 'mdi-phone';
export const plus = 'mdi-plus';
export const plusOutline = 'mdi-plus-circle-outline';
export const printer = 'mdi-printer';
export const receiptTextCheckOutline = 'mdi-receipt-text-check-outline';
export const receiptTextOutline = 'mdi-receipt-text-outline';
export const refresh = 'mdi-refresh';
export const redo = 'mdi-redo';
export const sale = 'mdi-point-of-sale';
export const save = 'mdi-content-save';
export const search = 'mdi-magnify';
export const security = 'mdi-shield-lock';
export const setCenter = 'mdi-set-center';
export const scaleUnbalanced = 'mdi-scale-unbalanced';
export const swapHorizontal = 'mdi-swap-horizontal';
export const table = 'mdi-table-furniture';
export const text = 'mdi-format-text-rotation-none';
export const tune = 'mdi-tune-vertical-variant';
export const user = 'mdi-account-settings';
export const users = 'mdi-account-multiple';
export const view = 'mdi-eye';
export const web = 'mdi-web';
export const ticketPercentOutline = 'mdi-ticket-percent-outline';
