<template>
  <v-icon :dense="dense" :color="color">{{ icon }}</v-icon>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
  },
};
</script>

<style></style>
