import { typePageOptions } from '@/utils';
import { text } from '@/utils/icons';
import { required } from '@/utils/rules';
import TypeInstallmentBusiness from '@/business/TypeInstallmentBusiness';

export default {
  domain: 'cost_centers',
  domainSingle: 'cost_center',
  business: TypeInstallmentBusiness,
  title: 'Centro de Custo',
  description: 'Lista de todos os Centro de Custo do sistema',
  createBtn: 'Novo Centro de Custo',
  showInactive: false,
  routes: {
    list: {
      name: 'cost-centers',
      path: '/centro-custos',
      meta: {
        rule: 'isAdmin',
        typePage: typePageOptions.list,
        requiresAuth: true,
      },
    },
    create: {
      name: 'cost-center-create',
      path: '/centro-custos/novo',
      meta: {
        rule: 'isAdmin',
        typePage: typePageOptions.create,
        requiresAuth: true,
      },
    },
    show: {
      name: 'cost-center-show',
      path: '/centro-custos/:id',
      meta: {
        rule: 'isAdmin',
        typePage: typePageOptions.show,
        requiresAuth: true,
      },
    },
  },
  listActions: {
    has: true,
    noShow: false,
    noDestroy: false,
    more: {
      has: false,
      items: [],
    },
  },
  filters: {
    has: false,
    items: [],
  },
  fields: [
    {
      title: 'Dados do Centro de Custo',
      icon: text,
      openGroup: true,
      readonly: true,
      items: [
        {
          type: 'text',
          name: 'description',
          formattedName: 'description',
          label: 'Descrição',
          align: '',
          md: '5',
          rules: [required],
          list: true,
          readonly: false,
          disabled: false,
        },
        {
          type: 'select',
          name: 'dad_id',
          formattedName: 'dad.description',
          label: 'Pai',
          align: '',
          md: '2',
          rules: [],
          list: true,
          readonly: false,
          disabled: false,
          items: [],
          service: {
            has: true,
            optional: true,
            verb: 'get',
            domain: 'cost_centers',
            endpoint: 'cost_centers',
            queryParams: '',
          },
          itemText: 'description',
          itemValue: 'id',
        },
      ],
    },
  ],
  footer: [],
};
