export default {
  domain: '',
  title: '',
  description: '',
  createBtn: '',
  routes: {
    forbidden: {
      name: 'forbidden',
      path: '/sem-permissao',
      meta: {
        rule: 'isUser',
        typePage: null,
        requiresAuth: true,
      }
    },
  },
};
