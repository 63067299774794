<template>
  <div class="content-main" :class="[height >= 500 ? 'padding-content-desktop' : 'padding-content-mobile', modeDark.value ? 'dark-mode' : 'no-dark-mode']">
    <div class="content-main-content">
      <div class="content-main-content--main">
        <router-view></router-view>
      </div>
    </div>
    <p class="text-caption text-center mt-5" x-small>Todos os direitos reservados à MGE Tech - Siscomge - {{ currentYear }}</p>
  </div>
</template>

<script>
import BreakPointMixin from '@/mixins/BreakPointMixin';
import { getCurrentPeriod } from '@/utils';

export default {
  name: 'ContentMain',
  props: {
    modeDark: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    currentYear() {
      return getCurrentPeriod().currentYear;
    }
  },
  mixins: [BreakPointMixin],
};
</script>

<style lang="scss" scope>

.dark-mode {
  background-color: #222 !important;
  color: #FFFFFF;
}
.no-dark-mode {
  background-color: #F6F7FB !important;
}

.content-main {
  min-height: calc(100vh - 64px) !important;
  width: 100%;
  background-color: #F6F7FB;
}

.padding-content-desktop {
  padding: 20px 20px 0 20px;
}

.padding-content-mobile {
  padding: 20px 20px 0 20px;
}

.content-main-title {
  justify-content: space-between;
  align-items: flex-end;
}

.content-main-content {
  display: flex;
  justify-content: space-between;

  .content-main-content--main {
    width: 100%;
  }

  .content-main-content--notifications {
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .content-main-content--notifications---cards {
      width: 90%;
      min-height: 150px;
      background-color: #FFFFFF;
      border-radius: 25px;
      padding: 15px;
      margin: 0 0 25px 0;
      -webkit-box-shadow: 0px 3px 5px -3px rgba(41,47,76,0.58);
      -moz-box-shadow: 0px 3px 5px -3px rgba(41,47,76,0.58);
      box-shadow: 0px 3px 5px -3px rgba(41,47,76,0.58) !important;

      h3 {
        font-size: 22px;
        font-weight: 700;
        color: #192A4C;
      }

      p {
        font-size: 14px;
        font-weight: 300;
        color: #192A4C;
        margin-top: 15px;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 1280px) {
  .padding-content-desktop {
    padding: 20px 20px 0 20px;
  }
}
</style>
