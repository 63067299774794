<template>
  <div>
    <ModalCardDefault title="Pagamentos" @close="close">
      <ExpansionPanel
        v-model="panel"
        title="Lista dos pagamentos da Assinatura"
        :icon="$icons.box"
        readonly
      >
        <DataTable
          no-search
          :items="item.payments"
          :headers="headers"
          :items-per-page="5"
          :slots="slotsItems"
          no-sync
        >
          <template v-slot:[`due_date`]="{ props: { item } }">
            {{ utils.formatDatePT(item.due_date) }}
          </template>

          <template v-slot:[`start_date`]="{ props: { item } }">
            {{ utils.formatDatePT(item.start_date) }}
          </template>

          <template v-slot:[`end_date`]="{ props: { item } }">
            {{ utils.formatDatePT(item.end_date) }}
          </template>

          <template v-slot:[`payment_method`]="{ props: { item } }">
            {{ getPaymentMethod(item.payment_method) }}
          </template>

          <template v-slot:[`status`]="{ props: { item, index } }">
            <Button
              v-if="displayButtonMakePayment(item)"
              label="Fazer Pagamento"
              small
              rounded
              color="success"
              :loading="loading"
              @click="makePayment(item, index)"
            />
            <Chip v-else label="Pago" color="success" />
          </template>

          <template v-slot:[`payment_date`]="{ props: { item } }">
            {{ utils.formatDatePT(item.payment_date) }}
          </template>

          <template v-slot:[`value`]="{ props: { item } }">
            {{ utils.formatCurrency(item.value) }}
          </template>
        </DataTable>
      </ExpansionPanel>
    </ModalCardDefault>

    <Dialog :dialog="dialogModalQrCode" no-title :maxWidth="600">
      <template v-slot:content>
        <ModalCardDefault
          title="Pagamento Via QR Code Pix"
          @close="toggleModalQrCode()"
        >
          <ExpansionPanel
            v-model="panel"
            title="QR Code Pix"
            :icon="$icons.box"
            readonly
          >
            <Row>
              <Col cols="12" class="d-flex flex-column align-center">
                <span
                  >Para realizar o pagamento, utilize o QR Code abaixo:</span
                >
                <img :src="qrCodeImage" width="180" />
              </Col>
            </Row>
            <Row>
              <Col cols="12" class="d-flex flex-column align-center">
                <span style="color: red; text-align: center"
                  >Se preferir clique no botão "Copiar Código" ou copie e cole a
                  código abaixo.</span
                >
                <Button
                  small
                  label="Copiar Código"
                  color="success"
                  rounded
                  class="my-2"
                  @click="copyToClipboard"
                />
                <code>{{ order.qr_code }}</code>
              </Col>
            </Row>
            <Row>
              <Col cols="12" class="d-flex flex-column align-center">
                <h2>{{ `Valor: ${utils.formatCurrency(order.amount)}` }}</h2>
              </Col>
            </Row>
            <Row>
              <Col cols="12" class="d-flex flex-column align-center">
                <span
                  >Aguardando confirmação do pagamento. Verificando a cada 5
                  segundos...</span
                >
                <v-progress-linear
                  v-if="loadingPayment"
                  color="success"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
                <Button
                  v-else
                  label="Pagamento Confirmado com Sucesso"
                  color="success"
                  rounded
                />
              </Col>
            </Row>
          </ExpansionPanel>
        </ModalCardDefault>
      </template>
    </Dialog>
  </div>
</template>

<script>
import ModalCardDefault from '@/components/ModalCardDefault';
import {
  ExpansionPanel,
  DataTable,
  Button,
  Dialog,
  Row,
  Col,
  Chip,
} from '@/components/vuetify';
import { formatDatePT, formatCurrency } from '@/utils';
import { required } from '@/utils/rules';
import QRCode from 'qrcode';

export default {
  name: 'ListSubscriptionPayments',
  components: {
    ModalCardDefault,
    ExpansionPanel,
    DataTable,
    Button,
    Dialog,
    Row,
    Col,
    Chip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    panel: [0],
    valid: false,
    utils: {
      formatDatePT,
      formatCurrency,
    },
    rules: {
      required,
    },
    slotsItems: [
      { field: 'due_date' },
      { field: 'start_date' },
      { field: 'end_date' },
      { field: 'payment_method' },
      { field: 'status' },
      { field: 'value' },
      { field: 'payment_date' },
    ],
    balanceItems: [],
    qrCodeImage: null,
    loading: false,
    dialogModalQrCode: false,
    order: {
      amount: 0,
      qr_code: '',
      qr_code_url: '',
    },
    intervalId: null,
    subscriptionPaymentSelected: {},
    loadingPayment: false,
    indexSubscriptionPaymentSelected: null,
  }),
  computed: {
    headers() {
      return [
        { text: 'Vencimento', value: 'due_date' },
        { text: 'Início Vigência', value: 'start_date' },
        { text: 'Fim Vigência', value: 'end_date' },
        { text: 'Forma Pagamento', value: 'payment_method' },
        { text: 'Valor', value: 'value' },
        { text: 'Data Pag.', value: 'payment_date' },
        { text: 'Status', value: 'status' },
      ];
    },
  },
  methods: {
    getPaymentMethod(paymentMethod) {
      return paymentMethod;
    },
    getStatus(status) {
      return status;
    },
    makePayment(item, index) {
      this.loading = true;
      this.subscriptionPaymentSelected = item;
      this.indexSubscriptionPaymentSelected = index;
      this.$api.subscriptions
        .generatePayment(item.subscription_id, item.id)
        .then((res) => {
          this.order = res;
          this.getQrCode();
        })
        .catch(() => {
          this.$noty.error('Erro ao gerar o Pagamento');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit('close');
    },
    getQrCode() {
      let qrCode = '';

      try {
        QRCode.toDataURL(this.order.qr_code, function (err, url) {
          if (!err) {
            qrCode = url;
          }
        });
        this.qrCodeImage = qrCode;
      } catch {
        this.$noty.error('Erro ao gerar o QR Code');
      } finally {
        this.loadingPayment = true;
        this.toggleModalQrCode();
        this.intervalId = setInterval(this.checkPayment, 5000);
      }
    },
    toggleModalQrCode() {
      this.dialogModalQrCode = !this.dialogModalQrCode;

      if (!this.dialogModalQrCode) {
        clearInterval(this.intervalId);
        this.subscriptionPaymentSelected = {};
        this.indexSubscriptionPaymentSelected = null;
      }
    },
    checkPayment() {
      this.loadingPayment = true;
      this.$api.subscriptions
        .getSubscriptionPayment(
          this.subscriptionPaymentSelected.subscription_id,
          this.subscriptionPaymentSelected.id
        )
        .then(({ subscription_payment }) => {
          this.subscriptionPaymentSelected = subscription_payment;

          if (subscription_payment.status === 'paid') {
            this.$noty.success('Pagamento Confirmado');
            clearInterval(this.intervalId);
            this.loadingPayment = false;
            this.item.payments[this.indexSubscriptionPaymentSelected].status =
              'paid';
          }
        })
        .catch(() => {
          this.$noty.error('Erro ao verificar o Pagamento');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    displayButtonMakePayment(item) {
      return item.status !== 'paid';
    },
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.order.qr_code);
        this.$noty.success('Código copiado com sucesso!');
      } catch (err) {
        this.$noty.error('Erro ao copiar o código');
      }
    },
  },
  mounted() {},
};
</script>
