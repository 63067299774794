import Vue from 'vue';
import VueRouter from 'vue-router';
import { jwtDecode } from 'jwt-decode';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  hash: false,
  routes,
});

function getUserRole() {
  const token = localStorage.getItem(`${process.env.VUE_APP_NAME}.token`);

  if (!token) return 'public';

  try {
    const decoded = jwtDecode(token);

    return decoded.role.toLowerCase() || 'public';
  } catch (error) {
    console.error('Erro ao decodificar o token:', error);

    return 'public';
  }
}

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem(`${process.env.VUE_APP_NAME}.token`);

  const routeRole = to.meta.rule || 'public';
  // const routeSubscription = to.meta.subscription || 'basic';

  if (to.name === 'login') {
    if (token) {
      return next({ name: 'home' });
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!token) {
      return next({ name: 'login' });
    }

    if (to.path !== '/sem-permissao' && getUserRole() === routeRole) {
      return next();
    } else if (to.path === '/sem-permissao') {
      return next();
    } else {
      return next();
      // return next('/sem-permissao');
    }
  }

  return next();
});

export default router;
