import axios from '@/service';
import DynamicService from '@/service/DynamicService';
import SubscriptionSchema from '../schemas/SubscriptionSchema';
import { getText, formatDate, formatCurrency } from '@/utils';
import { statusSubscriptions } from '@/utils/options';

const interval = {
  month: 'Mensal',
  year: 'Anual',
};

const currentCycleStatus = {
  billed: 'Faturado',
};

const formatResponse = (item) => {
  item.start_date_formatted = formatDate(item.start_date);
  item.company = item.company.name;
  item.status_formatted = getText(statusSubscriptions, item.status);
  item.cycles = item.cycles.map((cycle) => {
    return {
      ...cycle,
      interval_formatted: interval[cycle.interval],
      current_cycle_start_at_formatted: formatDate(
        cycle.current_cycle_start_at
      ),
      current_cycle_end_at_formatted: formatDate(cycle.current_cycle_end_at),
      current_cycle_billing_at_formatted: formatDate(
        cycle.current_cycle_billing_at
      ),
      next_billing_at_formatted: formatDate(cycle.next_billing_at),
      price_formatted: formatCurrency(cycle.price),
      current_cycle_status_formatted:
        currentCycleStatus[cycle.current_cycle_status],
    };
  });
};

const SubscriptionsService = DynamicService(
  'subscriptions',
  SubscriptionSchema,
  { formatResponse }
);

const SubscriptionsCommands = () => ({
  async generatePayment(subscriptionId, subscriptionPaymentId) {
    const { data } = await axios.post(
      `${SubscriptionSchema.domain}/${subscriptionId}/payments/${subscriptionPaymentId}/orders`
    );

    return data;
  },
  async getSubscriptionPayment(subscriptionId, subscriptionPaymentId) {
    const { data } = await axios.get(
      `${SubscriptionSchema.domain}/${subscriptionId}/payments/${subscriptionPaymentId}`
    );

    return data;
  },
});

export default SubscriptionsService;
export { SubscriptionsService, SubscriptionsCommands };
